<template>
  <div class="bg-light">
    <table>
      <tr>
        <th class="text-center">{{ $t("SL") }}</th>
        <th class="text-center">{{ $t("TIPSTER") }}</th>
        <th class="text-center">{{ $t("NO_TIPS") }}</th>
        <th class="text-center">{{ $t("HITS") }}</th>
        <th class="text-center">{{ $t("PROFITS") }}</th>
      </tr>
      <tr>
        <td class="text-center">1</td>
        <td class="text-center">
          <a
            class="m-0 p-0"
            style="color: #1c50ed"
            href="/soccer-tipster/henry-reginald"
          >
            Henry Reginald (Senior Tipsters)</a
          >
        </td>
        <td class="text-center">3553</td>
        <td class="text-center">79.19%</td>
        <td class="text-center">+16478.00</td>
        <td class="text-center">
          <button type="button" class="btn btn-primary btn-block btn-sm mr-4">
            {{ $t("UNFOLLOW") }}
          </button>
        </td>
        <td class="text-center">
          <button
            type="button"
            class="btn btn-primary btn-block btn-sm mr-4"
            data-toggle="collapse"
            :href="`#collapse-my-tipster`"
            role="button"
            aria-expanded="false"
            :aria-controls="`collapse-my-tipster`"
          >
            {{ $t("VIEW_MORE") }}
          </button>
        </td>
      </tr>
    </table>
    <div
      class="col-lg-12 collapse"
      :id="`collapse-my-tipster`"
      style="background-color: #dddddd"
    >
      <div class="d-flex align-items-center justify-content-between px-4">
        <div class="col-lg-2 mb-2">
          <div
            class="card text-center"
            style="
              background-color: rgba(0, 108, 9, 0.638);
              border: 1px solid rgba(3, 87, 0, 0.633);
              color: white;
            "
          >
            <div
              class="card-header p-0"
              style="
                background-color: rgba(0, 108, 9, 0.638);
                border: 1px solid rgba(3, 87, 0, 0.633);
              "
            >
              WIN
            </div>
            <div class="card-body p-0">
              <p class="card-title m-0">17</p>
            </div>
          </div>
        </div>
        <div class="col-lg-2 mb-2">
          <div
            class="card text-center"
            style="
              background-color: rgba(255, 149, 0, 0.638);
              border: 1px solid rgba(255, 119, 0, 0.633);
              color: white;
            "
          >
            <div
              class="card-header p-0"
              style="
                background-color: rgba(255, 149, 0, 0.638);
                border: 1px solid rgba(255, 119, 0, 0.633);
              "
            >
              PUSH
            </div>
            <div class="card-body p-0">
              <p class="card-title m-0">0</p>
            </div>
          </div>
        </div>
        <div class="col-lg-2 mb-2">
          <div
            class="card text-center"
            style="
              background-color: rgba(255, 0, 0, 0.638);
              border: 1px solid rgba(255, 0, 0, 0.633);
              color: white;
            "
          >
            <div
              class="card-header p-0"
              style="
                background-color: rgba(255, 0, 0, 0.638);
                border: 1px solid rgba(255, 0, 0, 0.633);
              "
            >
              FAIL
            </div>
            <div class="card-body p-0">
              <p class="card-title m-0">1</p>
            </div>
          </div>
        </div>
        <div class="col-lg-2 mb-2">
          <div class="card text-center">
            <div class="card-header p-0">ODDS</div>
            <div class="card-body p-0">
              <p class="card-title m-0">2.27</p>
            </div>
          </div>
        </div>
        <div class="col-lg-2 mb-2">
          <div class="card text-center">
            <div class="card-header p-0">RUNNING TIPS</div>
            <div class="card-body p-0">
              <p class="card-title m-0">2 tips</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    tipsters: {
      type: Object,
    },
  },
  components: {},
  mounted() {
    setInterval(() => {
      this.startTimer();
    }, 1000);
  },
  data() {
    return {
      show: true,
      countDownToTime: new Date("Sep 26, 2029 00:00:00").getTime(),
      timerOutput: null,
    };
  },
  methods: {
    sendName(val) {
      return `${val.toLowerCase().split(" ")[0]}-${
        val.toLowerCase().split(" ")[1]
      }`;
    },
    startTimer() {
      const timeNow = new Date().getTime();
      const timeDifference = this.countDownToTime - timeNow;
      const millisecondsInOneSecond = 1000;
      const millisecondsInOneMinute = millisecondsInOneSecond * 60;
      const millisecondsInOneHour = millisecondsInOneMinute * 60;
      const millisecondsInOneDay = millisecondsInOneHour * 24;
      const differenceInDays = timeDifference / millisecondsInOneDay;
      const remainderDifferenceInHours =
        (timeDifference % millisecondsInOneDay) / millisecondsInOneHour;
      const remainderDifferenceInMinutes =
        (timeDifference % millisecondsInOneHour) / millisecondsInOneMinute;
      const remainderDifferenceInSeconds =
        (timeDifference % millisecondsInOneMinute) / millisecondsInOneSecond;
      const remainingDays = Math.floor(differenceInDays);
      const remainingHours = Math.floor(remainderDifferenceInHours);
      const remainingMinutes = Math.floor(remainderDifferenceInMinutes);
      const remainingSeconds = Math.floor(remainderDifferenceInSeconds);
      this.timerOutput =
        remainingHours +
        "H:" +
        remainingMinutes +
        "M:" +
        remainingSeconds +
        "S";
    },
  },
};
</script>

<style lang="scss">
.status {
  background-color: rgb(0, 0, 0);
  border-radius: 4px;
  margin: 2px 0px 0px 0px;
  padding: 0px 6px 3px 6px !important;
  span {
    font-size: 12px;
    color: rgb(255, 255, 255);
  }
}
p {
  font-size: 12px;
}
a {
  font-size: 12px;
}
hr {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  border: 0 !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}
table {
  font-family: "Kanit", sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 12px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
/* we will explain what these classes do next! */
</style>
