<template>
  <div class="bg-light">
    <div class="container mb-4">
      <div class="row">
        <div class="col-lg-12">
          <div
            class="d-flex align-items-center justify-content-between bg-primary py-2 px-4"
          >
            <a class="m-0 text-white">Henry Reginald (Senior Tipsters) </a>
            <a href="#" class="text-white">{{ $t("REMOVE_ITEM") }}</a>
          </div>
          <div class="card">
            <table>
              <tr>
                <td class="text-center">
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <div class="m-0 p-0" style="color: #1c50ed">
                      09.10.23 18:30
                    </div>

                    Total goal(FullTime)
                  </div>
                </td>
                <td class="text-center" rowspan="2">
                  <button type="button" class="btn btn-primary btn-sm mr-4">
                    BUY THIS TIP FOR $60.00
                  </button>
                </td>
              </tr>
              <tr>
                <td class="text-left">
                  <div>
                    <h6>ALTA - BRANN 2</h6>
                    Norway 3-2. Divisjon Avd. 2
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    tipsters: {
      type: Object,
    },
  },
  components: {},
  mounted() {
    setInterval(() => {
      this.startTimer();
    }, 1000);
  },
  data() {
    return {
      show: true,
      countDownToTime: new Date("Sep 26, 2029 00:00:00").getTime(),
      timerOutput: null,
    };
  },
  methods: {
    sendName(val) {
      return `${val.toLowerCase().split(" ")[0]}-${
        val.toLowerCase().split(" ")[1]
      }`;
    },
    startTimer() {
      const timeNow = new Date().getTime();
      const timeDifference = this.countDownToTime - timeNow;
      const millisecondsInOneSecond = 1000;
      const millisecondsInOneMinute = millisecondsInOneSecond * 60;
      const millisecondsInOneHour = millisecondsInOneMinute * 60;
      const millisecondsInOneDay = millisecondsInOneHour * 24;
      const differenceInDays = timeDifference / millisecondsInOneDay;
      const remainderDifferenceInHours =
        (timeDifference % millisecondsInOneDay) / millisecondsInOneHour;
      const remainderDifferenceInMinutes =
        (timeDifference % millisecondsInOneHour) / millisecondsInOneMinute;
      const remainderDifferenceInSeconds =
        (timeDifference % millisecondsInOneMinute) / millisecondsInOneSecond;
      const remainingDays = Math.floor(differenceInDays);
      const remainingHours = Math.floor(remainderDifferenceInHours);
      const remainingMinutes = Math.floor(remainderDifferenceInMinutes);
      const remainingSeconds = Math.floor(remainderDifferenceInSeconds);
      this.timerOutput =
        remainingHours +
        "H:" +
        remainingMinutes +
        "M:" +
        remainingSeconds +
        "S";
    },
  },
};
</script>

<style lang="scss">
.status {
  background-color: rgb(0, 0, 0);
  border-radius: 4px;
  margin: 2px 0px 0px 0px;
  padding: 0px 6px 3px 6px !important;
  span {
    font-size: 12px;
    color: rgb(255, 255, 255);
  }
}
p {
  font-size: 12px;
}
a {
  font-size: 12px;
}
hr {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  border: 0 !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}
table {
  font-family: "Kanit", sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 12px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
/* we will explain what these classes do next! */
</style>
