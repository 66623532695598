<template>
  <div class="col-lg-3">
    <div
      class="d-flex align-items-center justify-content-between py-2 px-4 mb-3 bg-primary"
    >
      <h6 class="m-0 text-white">{{ $t("TOP_TIPSTERS") }}</h6>
    </div>

    <div class="card">
      <div class="card-body">
        <h6 class="m-0">NICK LAI</h6>
        <p>JUNIOR TIPSTERS</p>
        <div class="card">
          <div class="card-body p-2">
            <div class="d-flex align-items-center justify-content-between">
              <h6 style="color: green">WIN 15</h6>
              <h6 style="color: orange">DRAW 0</h6>
              <h6 style="color: red">LOSE 11</h6>
            </div>
          </div>
        </div>
        <div
          class="d-flex align-items-center justify-content-between mb-3 mt-3"
        >
          <button type="button" class="btn btn-primary btn-block btn-sm mr-4">
            {{ $t("FOLLOW") }}
          </button>
          <button type="button" class="btn btn-dark btn-block btn-sm m-0">
            {{ $t("VIEW_PROFILE") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  mounted() {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">
.status {
  background-color: rgb(0, 0, 0);
  border-radius: 4px;
  margin: 2px 0px 0px 0px;
  padding: 0px 6px 3px 6px !important;
  span {
    font-size: 12px;
    color: rgb(255, 255, 255);
  }
}
p {
  font-size: 12px;
}
</style>
