<template>
  <div>
    <Loading :loading="false" />
    <div class="container-fluid py-3">
      <div class="container">
        <div class="row">
          <div class="col-lg-9">
            <div
              class="d-flex align-items-center justify-content-between bg-primary py-2 px-4 mb-3"
            >
              <h6 class="m-0 text-white">
                {{ $t("TOP_PERFORMING_PREMIUM_TIPSTERS") }}
              </h6>
            </div>
            <div
              class="d-flex align-items-center justify-content-between bg-primary py-2 px-4"
            >
              <h6 class="m-0 text-white">
                {{ $t("YOUR_CREDITS") }}
              </h6>

              <h5 class="m-0 text-white">
                $0
                <div class="btn btn-light btn-sm">
                  <i class="bi-plus-lg"></i>
                </div>
              </h5>
            </div>
            <div class="card">
              <div class="card-body">
                <div class="d-flex justify-content-between px-4 mb-3">
                  <div>
                    <h5 class="card-title">SMILE COCI</h5>
                    <p class="card-text">Premium Tipsters</p>
                  </div>
                  <a href="#">{{ $t("EDIT_PROFILE") }}</a>
                </div>
              </div>
            </div>
            <div class="card mt-3">
              <div class="card-body">
                <div class="mini-nav mt-3">
                  <div class="row" style="margin-left: 0.01rem !important">
                    <div
                      :class="['link text-center', tab === 0 ? 'active' : '']"
                      @click="changeTab(0)"
                    >
                      {{ $t("MY_TIPSTER") }}
                    </div>

                    <div
                      :class="['link text-center', tab === 1 ? 'active' : '']"
                      @click="changeTab(1)"
                    >
                      {{ $t("MY_TIPSTER_TIPS") }}
                    </div>

                    <div
                      :class="['link text-center', tab === 2 ? 'active' : '']"
                      @click="changeTab(2)"
                    >
                      {{ $t("MY_TIPS_PERFORMANCE") }}
                    </div>

                    <div
                      :class="['link text-center', tab === 3 ? 'active' : '']"
                      @click="changeTab(3)"
                    >
                      {{ $t("PURCHASE_HISTORY") }}
                    </div>

                    <div
                      :class="['link text-center', tab === 4 ? 'active' : '']"
                      @click="changeTab(4)"
                    >
                      {{ $t("TRANSACTION_HISTORY") }}
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-body" v-if="tab === 0">
                    <MyTipster />
                  </div>
                  <div class="card-body" v-if="tab === 1">
                    <MyTipsterTips />
                  </div>
                  <div class="card-body" v-if="tab === 2">
                    <h6 class="card-title">{{ $t("MY_TIPS_PERFORMANCE") }}</h6>
                    <hr />
                    <p class="card-text mt-4"></p>
                  </div>
                  <div class="card-body" v-if="tab === 3">
                    <h6 class="card-title">{{ $t("PURCHASE_HISTORY") }}</h6>
                    <hr />
                    <p class="card-text mt-4"></p>
                  </div>
                  <div class="card-body" v-if="tab === 4">
                    <h6 class="card-title">{{ $t("TRANSACTION_HISTORY") }}</h6>
                    <hr />
                    <p class="card-text mt-4"></p>
                  </div>
                </div>
              </div>
            </div>

            <div class="card mt-3">
              <div class="card-body">
                <RuningTips />
              </div>
            </div>
          </div>

          <TopTipsters />
        </div>
      </div>
    </div>
    <!-- Main News Slider End -->
  </div>
</template>

<script>
import TopTipsters from "../components/TopTipsters.vue";
import LeaderCard from "../components/LeaderCard.vue";
import Loading from "../components/Loading.vue";
import TipstersCard from "../components/TipstersCard.vue";

import History from "../components/History.vue";
import RuningTips from "../components/RuningTips.vue";
import MyTipster from "./MyTipster.vue";
import MyTipsterTips from "./MyTipsterTips.vue";
export default {
  computed: {
    currentRouteName() {
      return this.$routes.history.current.params.name;
    },
  },
  components: {
    Loading,
    TipstersCard,
    LeaderCard,
    TopTipsters,
    History,
    RuningTips,
    MyTipsterTips,
    MyTipster,
  },
  mounted() {
    console.log(this.$routes.history.current.params.name);
  },
  data() {
    return {
      tipsters: [
        {
          rank: 1,
          name: "Netiphong Kanyala",
          profit: "+184.00",
        },
        {
          rank: 2,
          name: "RASMUS KOVALAINEN",
          profit: "+174.00",
        },
        {
          rank: 3,
          name: "CAUÃ£ ALMEIDA SILVA",
          profit: "+173.00",
        },
        {
          rank: 4,
          name: "BRANIMIR Ã…Â½IVKOVIÃ„Â€¡",
          profit: "+169.00",
        },
        {
          rank: 5,
          name: "CRISTIANO MONALDO",
          profit: "+164.00",
        },
      ],
      tipsters_week: [
        {
          rank: 1,
          name: "Netiphong Kanyala",
          profit: "+184.00",
        },
        {
          rank: 2,
          name: "RASMUS KOVALAINEN",
          profit: "+174.00",
        },
        {
          rank: 3,
          name: "CAUÃ£ ALMEIDA SILVA",
          profit: "+173.00",
        },
        {
          rank: 4,
          name: "BRANIMIR Ã…Â½IVKOVIÃ„Â€¡",
          profit: "+169.00",
        },
        {
          rank: 5,
          name: "CRISTIANO MONALDO",
          profit: "+164.00",
        },
      ],
      tab: 0,
    };
  },
  methods: {
    changeTab(index) {
      this.tab = index;
    },
  },
};
</script>

<style lang="scss">
.status {
  background-color: rgb(0, 0, 0);
  border-radius: 4px;
  margin: 2px 0px 0px 0px;
  padding: 0px 6px 3px 6px !important;
  span {
    font-size: 12px;
    color: rgb(255, 255, 255);
  }
}
.mini-nav {
  background-color: #1c50ed !important;
  height: 37px;
  width: 100%;
  left: 0;

  .link {
    height: 37px;
    width: 162px;
    left: 0;
    font-weight: bold;
    font-size: 14px;
    align-items: center !important;
    padding-top: 0.5rem !important;
    color: white;
    cursor: pointer;
  }
  .link:hover {
    background-color: #ffffff !important;
    color: #000000;
    cursor: pointer;
  }
  .active {
    background-color: #ffffff !important;
    color: #000000;
  }
  .active:hover {
    background-color: #ffffff !important;
    color: #000000;
  }
}
</style>
